body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// .language-js{
//   color:red;
// }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;

    &.language-js{
      color:green;
    }
}

table{
  border-bottom:solid 1px
  th {
    padding: auto 1rem;
  }
}

